@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-dark: 38 39 38;
  --color-light: 235 225 206;
  --color-accent: 220 146 117;
}

@layer base {
  @font-face {
    font-family: eaglebook;
    src: url("./assets/fonts/Eagle-Book.woff2") format("woff2");
  }
}

/* PRINTING/IMAGESTACKS - these are used for NFT printing or display in some capacity */
#printer {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

#printloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

#printloader .img-anim {
  animation: HOVER 2s ease-in-out infinite alternate;
}

#printloader .img-anim img {
  animation: SPIN 5s linear infinite;
  display: block;
  margin: auto;
  max-width: 80%;
  width: 200px;
}

@keyframes HOVER {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes SPIN {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.downloadingNFT #printloader {
  opacity: 1;
}

.downloadingNFT * {
  pointer-events: none;
}

.imagestack {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.imagestack img,
.imagestack svg {
  max-width: 100%;
}

.imagestack img ~ img,
.imagestack svg {
  /* Imagestack images are always sized after the first image, 
     since it assumes they're all the same width/height */
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

/* HEADER/FOOTER STUFF */
.hideMenuNav {
  display: none;
}

.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgb(var(--color-accent));
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.footersocial > svg {
  padding-left: 10%;
  padding-top: 10%;
  width: 1.75rem;
  height: 1.75rem;
}
